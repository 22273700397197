import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/users');

export function updateUserPassword(userId, payload) {
	return http.put(`/users/${userId}/password`, payload).then(resp => resp.data);
}

export function confirmUserInformation(userId, confirmed = false) {
	return http.put(`/admin/users/${userId}/confirm-information`, { confirmed }).then(resp => resp.data.data);
}

export function getIpLogs(userId) {
	return http.get(`/admin/users/${userId}/ip_logs`).then(resp => resp.data);
}

export function mapIpLogs(ips) {
	return http.post('/admin/users/map_ip_logs', { ips }).then(resp => resp.data);
}
